import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class UserHint extends React.Component {

  renderAlert() {
    let severity = "info";    //默认是info
    let text = "";            //默认没有信息
    if (this.props.hint === "login") {
      severity = "success";
      text = `${this.props.userInfo.user.nickname}，欢迎回来`;
    } else if (this.props.hint === "logout") {
      severity = "info";
      text = "您已登出，期待您再来看看";
    } else if (this.props.hint === "sendCaptcha") {
      severity = "info";
      text = "验证码已发送到您的邮箱";
    } else if (this.props.hint === "register") {
      severity = "success";
      text = "您的账号已被激活，请登录以访问更多";
    } else if (this.props.hint === "userUpdate") {
      severity = "success";
      text = "用户信息已更新";
    } else {
      return null;
    }

    return (
      <Alert severity={severity} onClose={this.props.handleClose}>
        {text}
      </Alert>
    )
  }

  render() {
    // 登陆成功等信息的提示框
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(this.props.hint)}
        autoHideDuration={6000}
        onClose={this.props.handleClose}
      >
        {this.renderAlert()}
      </Snackbar>
    );
  }
}

export default UserHint;