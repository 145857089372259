import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom'
import { alpha, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon'
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Link from '@material-ui/core/Link'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Tooltip from '@material-ui/core/Tooltip'
import axios, { USER_API, adminURL } from 'API'

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  link: {
    textDecoration: 'none'
  },
  title: {
    display: 'block',
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
  tabs: {
    marginLeft: theme.spacing(4),
  },
  tab: {
    position: "relative",
    '&::after': {
      content: '""',
      height: "2px",
      width: "0",
      position: "absolute",
      backgroundColor: "white",
      bottom: "0",
      transition: "1s width"
    },
    '&:hover::after': {
      content: '""',
      height: "2px",
      width: "70%",
      position: "absolute",
      backgroundColor: "white",
      bottom: "0",
      transition: "1s width"
    },
  },
  indicator: {
    backgroundColor: 'white',
  },
  search: {
    position: 'relative',
    color: 'white',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
});


class Headerbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,       // Menu的信息
      searchVal: "",      //搜索信息
    }
    this.handleProfileMenuOpen = this.handleProfileMenuOpen.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleSnackClose = this.handleSnackClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchPress = this.handleSearchPress.bind(this);
    this.barRef = React.createRef();
  }

  componentDidMount() {
    this.props.setHeaderHeight(this.barRef.current.offsetHeight);
    axios.request({
      url: USER_API.info.url,
      method: USER_API.info.method,
    }).then((response) => {
      // 修改全局用户信息
      this.props.setUserInfo(response.data, null);
    })
  }

  handleSearchPress(event) {
    const searchKeywords = this.state.searchVal.replace(/(^\s*)|(\s*$)/g, "");
    if (event.keyCode === 13) {
      const history = this.props.history;
      if (searchKeywords) {
        history.push(`/blog?key=${searchKeywords}`);
      } else {
        history.push(`/blog`);
      }
    }
  }

  handleSearchChange(event) {
    // console.log(event)
    this.setState({
      searchVal: event.target.value,
    })
  }

  handleProfileMenuOpen(event) {
    this.setState({
      anchorEl: event.currentTarget,    //设置菜单的锚点为当前的按钮
    })
  }

  handleMenuClose() {
    this.setState({
      anchorEl: null,
    })
  };

  handleTabChange(event, newVal) {
    this.props.onTabChange(newVal);
  }

  handleSnackClose(event, reason) {
    // 处理提示窗口关闭
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      loginSnackOpen: false,
    })
  }

  handleLogout() {
    axios.request({
      url: USER_API.logout.url,
      method: USER_API.logout.method,
    }).then((response) => {
      this.handleMenuClose();
      this.props.setUserInfo(response.data, 'logout');
    })
  }

  renderIconButtons() {
    // 右上角的图标按钮
    const { classes } = this.props;
    const AuthenticatedButtons = (
      <div className={classes.sectionDesktop}>
        <Tooltip arrow title="用户操作" placement="bottom">
          <IconButton
            onClick={this.handleProfileMenuOpen}
            color="inherit"
          >
            <SvgIcon viewBox="0 0 1024 1024">
              <path d="M512 1024C229.205 1024 0 794.795 0 512S229.205 0 512 0s512 229.205 512 512-229.205 512-512 512z m0-496.47a170.667 170.667 0 1 0 0-341.333 170.667 170.667 0 0 0 0 341.334z m263.765 263.723a263.765 263.765 0 1 0-527.53 0h527.53z" />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      </div>
    );

    const NoAuthenticatedButtons = (
      <div className={classes.sectionDesktop}>
        <Tooltip arrow title="注册" placement="bottom">
          <IconButton
            onClick={() => { this.props.setRegister(true) }}
            color="inherit"
          >
            <SvgIcon viewBox="0 0 1024 1024">
              <path d="M570.726 414.95c0 37.2-19.662 56.862-56.862 56.862h-59.752v-112.28h46.756c57.336 0 69.858 17.546 69.858 55.418zM1008 512c0 273.934-222.066 496-496 496S16 785.934 16 512 238.066 16 512 16s496 222.066 496 496zM726.822 720.828c-93.458-169.65-86.598-157.272-89.404-161.96 46.864-30.344 75.89-85.958 75.89-148.972 0-108.488-63-178.504-210.996-178.504h-141.334c-26.51 0-48 21.49-48 48V744c0 26.51 21.49 48 48 48h45.134c26.51 0 48-21.49 48-48v-143.326h51.112l88.258 165.874a48.002 48.002 0 0 0 42.376 25.454h48.928c36.522-0.002 59.658-39.182 42.036-71.174z" />
            </SvgIcon>
          </IconButton>
        </Tooltip>
        <Tooltip title="登录" placement="bottom">
          <IconButton
            onClick={() => this.props.setLogin(true)}
            color="inherit"
          >
            <SvgIcon viewBox="0 0 1024 1024">
              <path d="M832 896h-168c-13.2 0-24-10.8-24-24v-80c0-13.2 10.8-24 24-24h168c35.4 0 64-28.6 64-64V320c0-35.4-28.6-64-64-64h-168c-13.2 0-24-10.8-24-24V152c0-13.2 10.8-24 24-24h168c106 0 192 86 192 192v384c0 106-86 192-192 192z m-94-402L402 158c-30-30-82-9-82 34v192H48c-26.6 0-48 21.4-48 48v192c0 26.6 21.4 48 48 48h272v192c0 43 52 64 82 34l336-336c18.6-18.8 18.6-49.2 0-68z" p-id="8474" />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      </div >
    );
    const authenticated = this.props.userInfo.isLogin;
    return authenticated ? AuthenticatedButtons : NoAuthenticatedButtons;
  }

  renderMenu() {
    // 用户点击右上角按钮弹出的菜单
    const isMenuOpen = Boolean(this.state.anchorEl);
    const isSuperuser = this.props.userInfo.user ? this.props.userInfo.user.is_superuser : false;
    const isAuthor = this.props.userInfo.user ? this.props.userInfo.user.is_author : false;
    const menuId = 'primary-search-account-menu';
    return (
      <Menu
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem
          onClick={this.handleMenuClose}
          component={RouterLink} to="/userinfo">
          用户信息
        </MenuItem>
        {isSuperuser || isAuthor ?
          <MenuItem
            onClick={this.handleMenuClose}
            component={RouterLink} to="/blog/add">
            创作博客
          </MenuItem> : null}
        {isSuperuser ? (
          <MenuItem
            component={Link}
            href={adminURL}
            target="_blank"
            color="inherit"
            underline="none"
            rel="noreferrer"
            onClick={this.handleMenuClose}>后台管理</MenuItem>
        ) : null}
        <MenuItem onClick={this.handleLogout}>注销</MenuItem>
      </Menu>
    );
  }



  render() {
    const { classes } = this.props;
    // TODO: Add support for mobile
    return (
      <div className={classes.grow}>
        <AppBar color="primary" position="fixed" className={classes.appBar} ref={this.barRef}>
          <Toolbar>
            <Typography className={classes.title} variant="h5">
              <Link underline="none" component={RouterLink} to="/" color="inherit">良言</Link>
            </Typography>
            <Tabs value={this.props.tabVal} className={classes.tabs} classes={{
              indicator: classes.indicator,
            }} onChange={this.handleTabChange}>
              <Tab className={classes.tab} label="首页" value="home" component={RouterLink} to="/" />
              <Tab className={classes.tab} label="博客" value="blog" component={RouterLink} to="/blog" />
            </Tabs>
            <div className={classes.grow} />
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={this.state.searchVal}
                onChange={this.handleSearchChange}
                onKeyDown={this.handleSearchPress}
              />
            </div>
            {this.renderIconButtons()}
          </Toolbar>
        </AppBar>
        {this.renderMenu()}
      </div >
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Headerbar));
