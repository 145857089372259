import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  textField: {
    padding: theme.spacing(2, 0, 2, 0),
  },
  loginButton: {
    color: theme.palette.info.main,
  }
}));

function validatePassword(password) {
  if (/^[\u0021-\u007e]+$/g.test(password) && password.length >= 6 && password.length <= 20) {
    return true;
  } else {
    return false;
  }
}

function validateEmail(email) {
  if (/^[\w-]+@[\w-]+(\.[\w-]+)?\.(edu|com|cn|org|net)$/.test(email)) {
    return true;
  } else {
    return false;
  }
}

export default function ChangePasswordDialog(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    showPassword: false,    //是否明文显示密码
    password: "",           //输入的密码
    passwordConfirm: "",    //二次输入的密码
    passwordError: false,   //密码是否合法
    passwordConfirmError: false,   //二次输入的密码是否合法
    email: "",              //输入的邮箱
    emailError: false,      //邮箱是否合法
    captcha: "",            //输入的验证码
    captchaError: false,    //验证码是否合法
    captchaCounter: 0,      //发送验证码的间隔
  })

  const handleChange = (prop) => (event) => {
    if (prop === "password") {
      // 检查密码输入是否合法
      let passwordError = !validatePassword(event.target.value);
      setValues({ ...values, [prop]: event.target.value, passwordError });
    } else if (prop === "email") {
      // 检查邮箱输入是否合法
      let emailError = !validateEmail(event.target.value);
      setValues({ ...values, [prop]: event.target.value, emailError });
    } else if (prop === "passwordConfirm") {
      // 检查二次密码输入是否合法
      let passwordConfirmError = !validatePassword(event.target.value) || event.target.value !== values.password;
      setValues({ ...values, [prop]: event.target.value, passwordConfirmError });
    } else if (prop === "captcha") {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const onSubmit = () => {
    // TODO 向后台请求修改密码
  }

  const disableSubmit = () => {
    //返回Boolean: 是否禁止注册按钮
    if (values.email.length <= 0 || values.captcha.length <= 0 || values.password.length <= 0 || values.passwordConfirm.length <= 0) {
      // 初始状态或者有内容没有填入
      return true;
    } else if (values.usernameError || values.emailError || values.passwordError || values.passwordConfirmError) {
      return true;
    } else {
      return false;
    }
  }


  const onSendCaptcha = () => {
    // TODO 发送验证码到邮箱

    // 下面这个写法是为了让计时器立即执行一次
    let intervalId = setInterval(function interval() {
      if (values.captchaCounter >= 60) {
        setValues({ ...values, captchaCounter: 0 });
        clearInterval(intervalId);
      } else {
        setValues(values => ({ ...values, captchaCounter: values.captchaCounter + 1 }));
      }
      return interval;
    }(), 1000)
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth
      maxWidth="sm">
      <DialogTitle id="register-dialog-title">找回密码</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          请使用您注册时的邮箱找回密码
          <Typography variant="body2" color="secondary">
            验证码会发送到您的邮箱，请收到后填写在下方验证码处
          </Typography>
        </DialogContentText>
        <TextField className={classes.textField} required id="email" label="注册邮箱" fullWidth
          value={values.email}
          onChange={handleChange('email')}
          error={values.emailError}
          helperText={values.emailError ? "邮箱格式有误" : ""} />
        <TextField className={classes.textField} required id="captcha" label="验证码" fullWidth
          value={values.captcha}
          onChange={handleChange('captcha')}
          error={values.captchaError}
          helperText={values.emailError ? "验证码错误" : ""} />
        <TextField
          id="password" label="新密码（6-20位）" className={classes.textField}
          type={values.showPassword ? 'text' : 'password'}
          value={values.password}
          onChange={handleChange('password')}
          error={values.passwordError}
          helperText={values.passwordError ? "密码有误" : ""}
          fullWidth required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>)
          }}
        />

        <TextField
          id="password-confirm" label="密码确认" className={classes.textField}
          type={values.showPassword ? 'text' : 'password'}
          value={values.passwordConfirm}
          onChange={handleChange('passwordConfirm')}
          error={values.passwordConfirmError}
          helperText={values.passwordConfirmError ? "两次输入的密码不同" : ""}
          fullWidth required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          取消
        </Button>
        <Button onClick={onSendCaptcha} color="primary"
          disabled={values.email.length <= 0 || values.emailError || values.captchaCounter > 0}>
          {values.captchaCounter > 0 ? `${60 - values.captchaCounter}s` : "发送验证码"}
        </Button>
        <Button onClick={onSubmit} color="primary" disabled={disableSubmit()}>
          确认修改
        </Button>
      </DialogActions>
    </Dialog>
  );
}
