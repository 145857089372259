import axios from 'axios'

export const BLOG_API = {
  blog:{
    url: 'blog/',
    method: 'GET',
  },
  blogList: {
    url: 'blog/list/',
    method: 'GET',
  },
  blogDate:{
    url:'blog/dates/',
    method: 'GET',
  },
  blogCategory:{
    url:'blog/categories/',
    method: 'GET',
  },
  dailyPV: {
    url: '/blog/daily-pv/',
    method: 'GET',
  },
  pastSevenDaysPV: {
    url: 'blog/daily-pv/past-seven-days/',
    method: 'GET',
  },
  todayPV: {
    url: 'blog/daily-pv/today/',
    method: 'GET',
  },
  blogPV: {
    url: 'blog/blog-pv/',
    method: 'GET',
  }
}

export const USER_API = {
  info: {
    url: 'user/',
    method: 'GET',
  },
  login:{
    url: 'user/login/',
    method: 'POST',
  },
  logout:{
    url: 'user/logout/',
    method: 'DELETE',
  },
  sendCaptcha:{
    url: 'user/send-captcha/',
    method: 'POST',
  },
  activation:{
    url: 'user/activation/',
    method: 'POST',
  },
  update:{
    url:'user/update/',
    method: 'POST',
  },
}

let hostname = process.env.REACT_APP_HOSTNAME || 'http://huangliang.cc/';
hostname = hostname.endsWith('/') ? hostname : hostname + '/';
const baseURL =  hostname + 'api/';


export default axios.create({
  baseURL,
  timeout: 5000,
  withCredentials: true,
  responseType: 'json',
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken',
});

export const adminURL = `${hostname}admin/`
