import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Typography from '@material-ui/core/Typography'
import axios, { USER_API } from 'API'

const styles = theme => ({
  textField: {
    padding: theme.spacing(2, 0, 2, 0),
  },
  registerButton: {
    color: theme.palette.info.main,
  },
  changePasswordButton: {
    marginLeft: theme.spacing(2),
    marginRight: "auto",
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.palette.primary.light
  }
})

function validatePassword(password) {
  if (/^[\u0021-\u007e]+$/g.test(password) && password.length >= 6 && password.length <= 20) {
    return true;
  } else {
    return false;
  }
}

function validateEmail(email) {
  if (/^[\w-]+@[\w-]+(\.[\w-]+)*\.(edu|com|cn|org|net)$/.test(email)) {
    return true;
  } else {
    return false;
  }
}


class LoginDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,    //是否明文显示密码
      password: "",           //输入的密码
      passwordError: "",   //密码是否合法
      email: "",           //输入的用户名
      emailError: "",   //用户名是否合法
      snackShow: false,   //登录成功显示提示
      otherError: "",    //其他的错误
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onRegister = this.onRegister.bind(this);
    this.onLogin = this.onLogin.bind(this);
  }


  handleChange(prop) {
    return (event) => {
      if (prop === "password") {
        // 检查密码输入是否合法
        let passwordError = validatePassword(event.target.value) ? "" : "密码格式不正确";
        this.setState({ [prop]: event.target.value, passwordError });
      } else if (prop === "email") {
        let emailError = validateEmail(event.target.value) ? "" : "邮箱格式不正确"
        this.setState({ [prop]: event.target.value, emailError });
      }
    }
  }

  handleClickShowPassword() {
    this.setState(oldState => ({ showPassword: !oldState.showPassword }));
  }

  onChangePassword(event) {
    this.props.handleClose();
    this.props.setChangePassword(true);
  }

  onRegister(event) {
    this.props.handleClose();
    this.props.setRegister(true);
  }

  onLogin(event) {
    // 防止未输入时点击登陆按钮
    if (!this.state.email) {
      this.setState({ emailError: "邮箱不能为空" })
    } else if (!this.state.password) {
      this.setState({ passwordError: "密码不能为空" })
    } else {
      // TODO 后台发送登陆信息
      axios.request({
        url: USER_API.login.url,
        method: USER_API.login.method,
        data: {
          email: this.state.email,
          password: this.state.password,
        },
      }).then(response => {
        this.setState({
          snackShow: true,    //显示登录提示
        });
        // 修改全局用户信息
        this.props.setUserInfo(response.data, 'login');
      }).catch(error => {
        let otherError = "";
        for (const key in error.response.data) {
          otherError += error.response.data[key];
          otherError && this.setState({otherError});
        }
      })
    }
  }

  renderOtherError() {
    if (this.state.otherError) {
      return (
        <Typography variant="body2" color="secondary" component="span" display="block">
          {this.state.otherError}
        </Typography>
      )
    } else {
      return null;
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        fullWidth
        maxWidth="sm" >
        <DialogTitle id="login-dialog-title">登陆</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            欢迎来到良言博客，马上登陆吧
            {this.renderOtherError()}
          </DialogContentText>
          <TextField className={classes.textField} required id="email" label="邮箱" fullWidth
            value={this.state.email}
            onChange={this.handleChange('email')}
            error={this.state.emailError}
            helperText={this.state.emailError} />
          <TextField
            id="password" label="密码" className={classes.textField}
            type={this.state.showPassword ? 'text' : 'password'}
            value={this.state.password}
            onChange={this.handleChange('password')}
            error={this.state.passwordError}
            helperText={this.state.passwordError}
            fullWidth required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.handleClickShowPassword}
                  >
                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>)
            }}
          />
        </DialogContent>
        <DialogActions>
          <div
            className={classes.changePasswordButton}
            onClick={this.onChangePassword}
          >
            忘记密码？
        </div>
          <Button onClick={this.props.handleClose} color="primary">
            取消
        </Button>
          <Button onClick={this.onRegister} className={classes.registerButton}>
            注册
        </Button>
          <Button onClick={this.onLogin} color="primary">
            登陆
        </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LoginDialog);
