import React from 'react';
import { withRouter } from 'react-router-dom'
import Appbar from './Appbar/appbar';
import green from '@material-ui/core/colors/green'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles';
import Main from './Main/main'
import FootBar from './Footbar/footBar'
import Toolbar from '@material-ui/core/Toolbar';
import LoginDialog from './Component/loginDialog'
import RegisterDialog from './Component/registerDialog'
import ChangePasswordDialog from './Component/changePasswordDialog'
import UserHint from './Main/userHint'

const theme = createTheme({
  palette: {
    primary: {
      main: green[700]
    }
  }
})

class App extends React.Component {
  constructor(props) {
    super(props);
    const urlName = props.location.pathname;
    let initialTabVal = null;
    if (urlName.match(/^\/blog/gi)) {
      // 如果url匹配到了blog，那么设置初始的tab为blog
      initialTabVal = 'blog';
    } else {
      // 否则匹配到了home，那么设置初始的tab为home
      initialTabVal = 'home';
    }
    this.state = {
      tabVal: initialTabVal,
      headerHeight: 0,
      openLogin: false,
      openRegister: false,
      openChangePassword: false,
      userInfo: { isLogin: false, user: null },
      userHint: null,   //[null, 'login', 'logout']
    }

    this.setHeaderHeight = this.setHeaderHeight.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.setLoginDialog = this.setLoginDialog.bind(this);
    this.setRegisterDialog = this.setRegisterDialog.bind(this);
    this.setUserInfo = this.setUserInfo.bind(this);
    this.setUserHint = this.setUserHint.bind(this);
    this.setChangePasswordDialog = this.setChangePasswordDialog.bind(this);
    this.onCloseLoginDialog = this.onCloseLoginDialog.bind(this);
    this.onCloseRegisterDialog = this.onCloseRegisterDialog.bind(this);
    this.onCloseHint = this.onCloseHint.bind(this);
    this.onCloseChangePasswordDialog = this.onCloseChangePasswordDialog.bind(this);
  }

  /**
   * 获取headerbar的高度
   */
  setHeaderHeight(height) {
    this.setState({
      // headerHeight: this.appBarRef.current.offsetHeight,
      headerHeight: height,
    })
  }

  /**
   * 设置Appbar的tab
   */
  handleTabChange(newVal) {
    if (this.state.tabVal !== newVal) {
      this.setState({
        tabVal: newVal,
      });
    }
  }

  /**
   * 设置登录的对话框显示状态
   */
  setLoginDialog(status) {
    this.setState({
      openLogin: status,
    })
  }

  /**
   * 关闭登录的对话框
   */
  onCloseLoginDialog() {
    this.setLoginDialog(false);
  }

  /**
   * 设置注册的对话框显示状态
   */
  setRegisterDialog(status) {
    this.setState({
      openRegister: status,
    })
  }

  /**
   * 关闭注册的对话框
   */
  onCloseRegisterDialog() {
    this.setRegisterDialog(false);
  }

  /**
   * 设置修改密码的对话框显示状态
   */
  setChangePasswordDialog(status) {
    this.setState({
      openChangePassword: status,
    })
  }

  /**
   * 关闭修改密码的对话框
   */
  onCloseChangePasswordDialog() {
    this.setChangePasswordDialog(false);
  }

  /**
   * 关闭登录和注册提示
   */
  onCloseHint(event, reason) {
    if (reason !== 'clickaway') {
      this.setState({
        userHint: null,
      })
    }
  }

  /**
   * @params data:  后台返回的用户信息
   * @params hint: 登录即为'login'，登出为'logout'，其他为null
   */
  setUserInfo(data, hint) {
    this.setState({
      userInfo: {
        isLogin: data.is_login,
        user: data.user,
      },
      openLogin: false,     //关闭登录界面
      openRegister: false,  //关闭注册界面
      userHint: hint,
    })
  }

  setUserHint(hint) {
    this.setState({
      userHint: hint,
    })
  }

  render() {
    const siteDescription = "京ICP备20026653号 Copyright \u00A9 2021 huangliang.cc 版权所有";
    const ownerInfo = "意见反馈或联系作者(1046678590@qq.com)";
    return (
      <ThemeProvider theme={theme}>
        <Appbar
          setHeaderHeight={this.setHeaderHeight}
          tabVal={this.state.tabVal}
          userInfo={this.state.userInfo}
          onTabChange={this.handleTabChange}
          setLogin={this.setLoginDialog}
          setRegister={this.setRegisterDialog}
          setUserInfo={this.setUserInfo} />
        <Toolbar />
        <Main
          onTabChange={this.handleTabChange}
          headerHeight={this.state.headerHeight}
          setUserInfo={this.setUserInfo}
          userInfo={this.state.userInfo} />
        <FootBar description={siteDescription} info={ownerInfo} />
        <LoginDialog
          open={this.state.openLogin}
          handleClose={this.onCloseLoginDialog}
          setRegister={this.setRegisterDialog}
          setChangePassword={this.setChangePasswordDialog}
          setUserInfo={this.setUserInfo}
        />
        <RegisterDialog
          open={this.state.openRegister}
          handleClose={this.onCloseRegisterDialog}
          setLogin={this.setLoginDialog}
          setUserHint={this.setUserHint}/>
        <ChangePasswordDialog
          open={this.state.openChangePassword}
          handleClose={this.onCloseChangePasswordDialog}
        />
        <UserHint
          hint={this.state.userHint}
          handleClose={this.onCloseHint}
          userInfo={this.state.userInfo} />
      </ThemeProvider>
    )
  }
}

export default withRouter(App);
