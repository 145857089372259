import React from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 0),
    }
  },
  item: {
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  subLink: {
    color: theme.palette.grey[500],
  },
  ul: {
    listStyle: "none",
    padding: "0",
    margin: "0",
  },
  li: {
    padding: "6px 0",
  },
  greyFont: {
    color: theme.palette.grey[500],
  }
}))

function FootBar(props) {
  const classes = useStyles();
  return (
    <div className={classes.root} >
      <Divider />
      <Container maxWidth="md" className={classes.container}>
        <Grid container>
          <Grid item sm={3} />
          <Grid item xs={6} sm={3} className={classes.item}>
            <Typography variant="subtitle1">
              <Link to="/" component={RouterLink} color="inherit">
                良言
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.item}>
            <Typography variant="subtitle1">开源</Typography>
            <ul className={classes.ul}>
              <li className={classes.li}>
                <Typography variant="body2">
                  <Link href="https://gitee.com/huang-jl/liangyan-frontend" className={classes.subLink}>
                    前端代码
                 </Link>
                </Typography>
              </li>
              <li className={classes.li}>
                <Typography variant="body2">
                  <Link href="https://gitee.com/huang-jl/liangyan-backend" className={classes.subLink}>
                    后端代码
                  </Link>
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item sm={3} />
        </Grid>
        <Typography className={classes.greyFont} align="center" variant="caption" variantMapping={{ caption: "p" }}>
          {props.description}
        </Typography>
        <Typography className={classes.greyFont} align="center" variant="caption" variantMapping={{ caption: "p" }}>
          {props.info}
        </Typography>
      </Container>
    </div>
  )
}

export default FootBar;