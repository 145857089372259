import React, { lazy, Suspense } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Switch, Route } from 'react-router-dom'
import Loading from 'Component/loading'
// import Home from './Home/home'
// import Blog from './Blog/blog'
// import UserInfo from './UserInfo/userInfo'

// lazy load for code split
const Home = lazy(() => import('./Home/home'));
const Blog = lazy(() => import('./Blog/blog'));
const UserInfo = lazy(() => import('./UserInfo/userInfo'));

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5),
  }
}));

function Main(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/blog">
            <Blog
              userInfo={props.userInfo}
              onTabChange={props.onTabChange}
              headerHeight={props.headerHeight} />
          </Route>
          <Route path="/userinfo">
            <UserInfo
              onTabChange={props.onTabChange}
              userInfo={props.userInfo}
              setUserInfo={props.setUserInfo} />
          </Route>
          <Route path="/">
            <Home onTabChange={props.onTabChange} />
          </Route>
        </Switch>
      </Suspense>
    </div>
  )
}

export default Main;