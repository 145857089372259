import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText'
import axios, { USER_API } from 'API'

const styles = theme => ({
  textField: {
    padding: theme.spacing(2, 0, 2, 0),
  },
  loginButton: {
    color: theme.palette.info.main,
  },
  nicknameTextField: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sexSelector: {
    minWidth: "120px",
  },
  captchaButton: {
    marginLeft: theme.spacing(2),
  },
  captchaTextField: {
    flexGrow: 1,
  }
});

function validatePassword(password) {
  if (/^[\u0021-\u007e]+$/g.test(password) && password.length >= 6 && password.length <= 20) {
    return true;
  } else {
    return false;
  }
}

function validateEmail(email) {
  if (/^[\w-]+@[\w-]+(\.[\w-]+)*\.(com|cn|org|net)$/.test(email)) {
    return true;
  } else {
    return false;
  }
}

function validateNickname(nickname) {
  if (nickname.length >= 3 && nickname.length <= 30) {
    return true;
  } else {
    return false;
  }
}

class RegisterDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,    //是否明文显示密码
      password: "",           //输入的密码
      passwordConfirm: "",    //二次输入的密码
      passwordError: "",   //密码是否合法
      passwordConfirmError: false,   //二次输入的密码是否合法
      nickname: "",           //输入的用户名
      nicknameError: "",   //用户名是否合法
      email: "",              //输入的邮箱
      emailError: "",      //邮箱是否合法
      sex: 2,                 //默认的性别是保密 (0:男 1:nv 2:保密)
      captchaCounter: 0,      //发送验证邮件的间隔
      captcha: "",             //输入的验证码
      captchaError: "",     //验证码是否正确
      otherError: "",        //其他的错误
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onSendCaptcha = this.onSendCaptcha.bind(this);
    this.onRegister = this.onRegister.bind(this);
  }

  handleChange(prop) {
    // 返回一个函数
    return (event) => {
      if (prop === "password") {
        // 检查密码输入是否合法
        let passwordError = validatePassword(event.target.value) ? "" : "密码格式有误";
        this.setState({ [prop]: event.target.value, passwordError });
      } else if (prop === "nickname") {
        // 检查用户名输入是否合法
        let nicknameError = validateNickname(event.target.value) ? "" : "昵称格式有误";
        this.setState({ [prop]: event.target.value, nicknameError });
      } else if (prop === "email") {
        // 检查邮箱输入是否合法
        let emailError = validateEmail(event.target.value) ? "" : "邮箱格式有误";
        this.setState({ [prop]: event.target.value, emailError });
      } else if (prop === "passwordConfirm") {
        // 检查二次密码输入是否合法
        let passwordConfirmError = (validatePassword(event.target.value) &&
          event.target.value === this.state.password) ? "" : "两次密码不一致";
        this.setState({ [prop]: event.target.value, passwordConfirmError });
      } else {
        this.setState({ [prop]: event.target.value })
      }
    }
  };

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  };

  onLogin() {
    this.props.handleClose();
    this.props.setLogin(true);
  }

  /**
   * 是否禁止注册按钮
   */
  disableRegister() {
    if (this.state.nicknameError || this.state.emailError || this.state.captchaError ||
      this.state.passwordError || this.state.passwordConfirmError) {
      return true;
    } else {
      return false;
    }
  }


  onSendCaptcha() {
    // 发送验证码到邮箱
    axios.request({
      url: USER_API.sendCaptcha.url,
      method: USER_API.sendCaptcha.method,
      data: {
        email: this.state.email,
        nickname: this.state.nickname,
        password: this.state.password,
        sex: this.state.sex,
      }
    }).then(() => {
      this.props.setUserHint('sendCaptcha');
    }).catch(error => {
      let otherError = "";
      for (const key in error.response.data) {
        if (key === 'email') {
          this.setState({
            emailError: error.response.data[key]
          })
        } else {
          otherError += error.response.data[key];
        }
      }
      otherError && this.setState({ otherError });
    });
    // 60s内不允许再次发送验证码
    // 下面这个写法是为了让倒计时立即开始
    this.setState({
      captchaCounter: 1,
    })
    let intervalId = setInterval(() => {
      if (this.state.captchaCounter >= 60) {
        this.setState({ captchaCounter: 0 });
        clearInterval(intervalId);
      } else {
        this.setState(values => ({ captchaCounter: values.captchaCounter + 1 }));
      }
    }, 1000)
  }

  onRegister() {
    if (!this.state.email) {
      this.setState({ emailError: "邮箱不能为空" })
    } else if (!this.state.nickname) {
      this.setState({ nicknameError: "昵称不能为空" })
    } else if (!this.state.password || !this.state.passwordConfirm) {
      this.setState({ passwordError: "密码不能为空" })
    } else if (!this.state.captcha) {
      this.setState({ captchaError: "验证码不能为空" })
    }
    // TODO 后台发送注册信息
    axios.request({
      url: USER_API.activation.url,
      method: USER_API.activation.method,
      data: {
        email: this.state.email,
        captcha: this.state.captcha,
      }
    }).then(response => {
      // 注册成功
      this.props.setUserHint('register');
      this.props.handleClose();
      this.props.setLogin(true);
    }).catch(error => {
      for (const key in error.response.data) {
        if (key === 'captcha') {
          this.setState({
            captchaError: error.response.data[key]
          })
        } else if (key === 'email') {
          this.setState({
            emailError: error.response.data[key]
          })
        }
      }
    })
    // console.log(`nickname: ${this.state.nickname}, password: ${this.state.password}`)
  }

  renderSexSelector() {
    const { classes } = this.props;
    return (
      <FormControl className={classes.sexSelector}>
        <FormHelperText>性别</FormHelperText>
        <Select
          labelId="sex-select-helper-label"
          id="sex-select-helper"
          value={this.state.sex}
          onChange={this.handleChange('sex')}
        >
          <MenuItem value={0}>男</MenuItem>
          <MenuItem value={1}>女</MenuItem>
          <MenuItem value={2}>保密</MenuItem>
        </Select>
      </FormControl>
    );
  }

  renderNicknameTextField() {
    const { classes } = this.props;
    return (
      <TextField className={classes.nicknameTextField} required id="nickname" label="昵称（3-30位）"
        value={this.state.nickname}
        onChange={this.handleChange('nickname')}
        error={this.state.nicknameError}
        helperText={this.state.nicknameError} />
    )
  }

  /**
   * 判断是否可以发送验证码
   * @return 返回true表示可以发送, false表示不能发送
   */
  getCaptchaStatus() {
    if (this.state.captchaCounter > 0) {
      return false; // 60s内不能多次发送
    } else if (this.state.email.length <= 0 || this.state.emailError) {
      return false; // 检查邮箱
    } else if (this.state.nickname.length <= 0 || this.state.nicknameError) {
      return false; // 检查昵称
    } else if (this.state.password.length <= 0 || this.state.passwordConfirm.length <= 0 ||
      this.state.passwordError || this.state.passwordConfirmError) {
      return false; // 检查密码
    }
    return true;
  }

  renderCaptcha() {
    const { classes } = this.props;
    return (
      <div className={classes.flex}>
        <TextField
          className={classes.captchaTextField}
          required id="captcha-text-field" label="验证码"
          value={this.state.captcha}
          onChange={this.handleChange('captcha')}
          error={this.state.captchaError}
          helperText={this.state.captchaError}
        />
        <Button onClick={this.onSendCaptcha}
          color="primary"
          variant="contained"
          disabled={!this.getCaptchaStatus()}
          className={classes.captchaButton}>
          {this.state.captchaCounter > 0 ? `${60 - this.state.captchaCounter}秒` : "发送验证码"}
        </Button>
      </div>
    )
  }

  renderOtherError() {
    if (this.state.otherError) {
      return (
        <Typography variant="body2" color="secondary" component="span" display="block">
          {this.state.otherError}
        </Typography>
      )
    } else {
      return null;
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        fullWidth
        maxWidth="sm" >
        <DialogTitle id="register-dialog-title">注册</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            欢迎来到良言博客，还不快快注册？
          <Typography variant="body2" color="secondary" component="span" display="block">
              请使用发送到您邮箱的验证码，激活您的账号
          </Typography>
            {this.renderOtherError()}
          </DialogContentText>
          <TextField className={classes.textField} required id="email" label="邮箱" fullWidth
            value={this.state.email}
            onChange={this.handleChange('email')}
            error={this.state.emailError}
            helperText={this.state.emailError} />
          <div className={classes.flex}>
            {this.renderSexSelector()}
            {this.renderNicknameTextField()}
          </div>
          <TextField
            id="password" label="密码（6-20位）" className={classes.textField}
            type={this.state.showPassword ? 'text' : 'password'}
            value={this.state.password}
            onChange={this.handleChange('password')}
            error={this.state.passwordError}
            helperText={this.state.passwordError ? "密码格式有误" : ""}
            fullWidth required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.handleClickShowPassword}
                  >
                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>)
            }}
          />
          <TextField
            id="password-confirm" label="密码确认" className={classes.textField}
            type={this.state.showPassword ? 'text' : 'password'}
            value={this.state.passwordConfirm}
            onChange={this.handleChange('passwordConfirm')}
            error={this.state.passwordConfirmError}
            helperText={this.state.passwordConfirmError}
            fullWidth required
          />
          {this.renderCaptcha()}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            取消
        </Button>
          <Button onClick={this.onLogin} className={classes.loginButton}>
            登陆
        </Button>
          <Button onClick={this.onRegister} color="primary" disabled={this.disableRegister()}>
            注册
        </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(RegisterDialog);